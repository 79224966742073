















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getIntegration, getWaTemplates } from '@/api/consoleApi/contents/whatsapp'
import VsWhatsappIntergation from '@/modules/whatsapp/components/VsWhatsappIntegration/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsWhatsappMobile from '@/components/VsWhatsappMobile/Index.vue'
import { sendWhastappMessage } from '@/api/consoleApi/recipients'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import moment from 'moment'
@Component({
    name: 'VsWhatsappModal',
    components: {
        VsWhatsappIntergation,
        VsLoader,
        VsContainer,
        VsSidebarLayout,
        VsFullTopBarLayout,
        VsWhatsappMobile,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) contact!: any
    @Prop({ default: () => [], required: true }) customFields!: any[]
    @Prop({ default: () => [], required: true }) customFieldsValues!: any[]
    private open = false
    private integration = null
    private templates: any[] = []
    private selectedTemplate = ''
    public loading = false
    $refs: any

    get selectedTemplateObject () {
        return this.templates.find(el => el.id === this.selectedTemplate)
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get templateNotSendable () {
        if (!this.selectedTemplateObject) return false
        return moment().diff(moment.unix(this.selectedTemplateObject.created_at), 'minutes') <= 20
    }

    get cfValues () {
        if (!this.selectedTemplateObject) return []
        const cfValues: any = []
        if (this.selectedTemplateObject.mapped_fields && this.selectedTemplateObject.mapped_fields.length > 0) {
            this.selectedTemplateObject.mapped_fields.forEach((field: string) => {
                const cf: any = this.customFields.find(el => el.placeholder === this.extractSubscriberPlaceholder(field))
                cfValues.push({
                    field,
                    cf,
                    value: cf ? this.customFieldsValues[cf.id + '' as any] : '',
                })
            })
        }
        return cfValues
    }

    get formattedBody () {
        if (!this.selectedTemplateObject) return
        let parsedBody = this.selectedTemplateObject.body
        if (this.cfValues.length > 0) {
            this.cfValues.forEach((field: any, index: number) => {
                // const fieldValue = this.contact.custom_fields
                parsedBody = parsedBody.replace(`{{${index + 1}}}`, field.value)
            })
        }
        return parsedBody
    }

    extractSubscriberPlaceholder (placeholder: string) {
        const regex = /%Subscriber:(.*?)%/
        const match = placeholder.match(regex)
        return match ? match[1] : null
    }

    public async openModal () {
        this.loading = false
        this.selectedTemplate = ''
        this.open = true
        await this.getIntegration()
        await this.getWhatsappTemplates('')
    }

    public closeModal () {
        this.open = false
    }

    async save () {
        return ''
    }

    private async getIntegration () {
        try {
            const resp = await getIntegration()
            this.integration = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    private async sendWhastappMessage () {
        this.loading = true
        try {
            await sendWhastappMessage(this.listId, this.contactId, {
                template_id: this.selectedTemplate,
            })
            this.$root.$vsToast({
                heading: 'Messaggio inviato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            const code = get(e, 'response.data.error', '')
            let message = ''

            if (code === 'TemplateParamsRequired') {
                message = 'I placeholders non sono valorizzati'
            }

            if (code === 'TemplateMustbeApproved') {
                message = 'Il template non è approvato'
            }

            if (code === 'TemplateNotSendable') {
                message = 'Puoi inviare un messaggio con questo template dopo 20 minuti dalla creazione'
            }

            const httpCode = get(e, 'response.status')

            if (httpCode === 403) {
                message = 'Per inviare il messaggio devi avere almeno 2 crediti sms'
            }

            this.$root.$vsToast({
                heading: 'Errore durante l\'invio del messaggio',
                aspect: VsToastAspectEnum.alert,
                message,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    async getWhatsappTemplates (search: string) {
        this.loading = true
        try {
            const resp = await getWaTemplates(this.buildParams(search))
            this.templates = resp.data.data
        } catch (e) {
            console.log(e)
            this.templates = []
        }
        this.loading = false
    }

    private buildParams (search: string) {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            searchJoin: 'and',
            page: 1,
            limit: 50,
        }

        const searches = []
        const searchesFields = []

        searches.push('approved')
        searchesFields.push('status')

        if (search && search.trim() !== '') {
            searches.push(search)
            searchesFields.push('name')
        }
        params.search = searches.join(';')
        params.searchFields = searchesFields.join(';')
        return params
    }

    formatBadgeLabel () {
        if (!this.selectedTemplateObject) return ''

        return this.selectedTemplateObject.name
    }
}
