var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Invia una campagna ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('general.close'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"disabled":!_vm.currentCampaign},on:{"click":_vm.submit}},[_vm._v(" Invia ")])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"vs-mt-4 vs-mb-4"},[_c('VsInputSearch',{staticClass:"vs-w-full vs-mb-6",attrs:{"label":"Scegli una campagna inviata da mandare a questo contatto","placeholder":"Ricerca campagne","value":_vm.currentCampaign ? _vm.currentCampaign.id : '',"boundary":"window","noOptionsResultText":"Nessuna campagna trovata","loadingText":_vm.$t('topnav.searching'),"loading":_vm.loading,"options":_vm.campaignOptions,"formatBadgeLabel":function () { return _vm.currentCampaign ? _vm.currentCampaign.name : ''; },"debounce":500},on:{"search":_vm.getCampaigns,"input":_vm.setCurrentCampaign}}),(_vm.currentCampaign)?_c('VsNotification',{staticClass:"vs-mb-2",attrs:{"variant":"info","iconName":_vm.currentCampaign.content_type === 'email' ? 'mail' : 'mobile-portrait'},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" "+_vm._s(_vm.currentCampaign.name)+" ")]},proxy:true}],null,false,3780593638)},[_c('div',{staticClass:"vs-body-medium"},[_vm._v(" Inviata il "+_vm._s(_vm._f("formatDate")(_vm.currentCampaign.sent_start))+" ")])]):_vm._e(),(_vm.currentCampaign && _vm.currentCampaign.content_type === 'email')?_c('div',{staticClass:"vs-m-auto",staticStyle:{"width":"300px"}},[(_vm.previewImage)?_c('VsOverlayCard',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary","size":"small","target":"_blank","to":{
                            name: 'campaignEdit',
                            params: {
                                campaignId: _vm.currentCampaign.id,
                            }
                        }}},[_vm._v(" Vai alla campagna ")])]},proxy:true}],null,false,2204582695)},[_c('div',{staticClass:"aspect-ratio"},[_c('img',{staticClass:"vs-rounded vs-w-full",attrs:{"src":_vm.previewImage}})])]):_vm._e()],1):_vm._e(),(_vm.currentCampaign && _vm.currentCampaign.content_type === 'sms')?_c('div',{staticClass:"vs-m-auto vs-relative",staticStyle:{"width":"400px"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('VsPhonePreview',{staticClass:"vs-mt-4 vs-mx-auto",attrs:{"content":_vm.currentCampaign.sms.data.body,"messageLength":_vm.previewCounter.per_message}}),(_vm.hover)?_c('div',{staticClass:"vs-rounded vs-absolute vs-top-0 vs-left-0 vs-w-full vs-h-full vs-flex vs-items-center vs-justify-center vs-radius-medium",staticStyle:{"background":"rgba(255, 255, 255, 0.5)"}},[_c('VsButton',{attrs:{"aspect":"secondary","size":"small","target":"_blank","to":{
                        name: 'campaignEdit',
                        params: {
                            campaignId: _vm.currentCampaign.id,
                        }
                    }}},[_vm._v(" Vai alla campagna ")])],1):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }