






















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsPhonePreview from '@/modules/campaigns/components/VsPhonePreview/Index.vue'
import VsOverlayCard from '@/components/VsOverlayCard/Index.vue'
import { getCampaigns } from '@/api/consoleApi/campaigns'
import { SmsCounter } from '@/utils/smsCounter'
import { sendCampaignToContact } from '@/api/consoleApi/recipients'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsSendSingleCampaignModal',
    components: {
        VsLoader,
        VsPhonePreview,
        VsOverlayCard,
    },
})
export default class extends Vue {
    @Prop({ default: false }) public canSendEmail!: boolean
    @Prop({ default: false }) public canSendSms!: boolean

    private show = false
    private loading = false
    private hover = false
    private campaigns = []
    private currentCampaign: any = null
    private campaignMessageId = 0
    private searchFilter: any[] = []
    private searchFieldsFilter: any[] = []
    private smsCounter = new SmsCounter()

    $refs: any

    get canSend () {
        if (this.canSendEmail && this.canSendSms) return 'email,sms'
        if (this.canSendEmail) return 'email'
        if (this.canSendSms) return 'sms'
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get campaignOptions () {
        return this.campaigns.filter((el: any) => el.content_type).map((el: any) => {
            return {
                value: el.id,
                label: el.name + ` (${el.content_type.toUpperCase()})`,
            }
        })
    }

    get previewCounter () {
        if (!this.currentCampaign) return false
        return this.smsCounter.count(this.currentCampaign.sms.data.body)
    }

    get defaultImage () {
        if (!this.currentCampaign) return ''
        return this.currentCampaign.content_type === 'email' ? require('@/assets/img/campaigns/email-placeholder.png') : require('@/assets/img/campaigns/sms-placeholder.png')
    }

    get previewImage () {
        if (!this.currentCampaign) return ''
        return this.currentCampaign.thumbnailUrl ? this.currentCampaign.thumbnailUrl + `?t=${Date.now()}` : this.defaultImage
    }

    get user () {
        return UserModule.user
    }

    get Mixpanel () {
        return UserModule.Mixpanel
    }

    async submit () {
        if (!this.currentCampaign) {
            return
        }
        try {
            await sendCampaignToContact(this.listId, this.contactId, this.currentCampaign.id)
            this.$root.$vsToast({
                heading: 'Campagna inviata con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.trackMixpanelEvent('Campaign Single Sent', this.currentCampaign)
        } catch (e) {
            console.log(e)
            const heading = 'Errore durante l\'invio della campagna'
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 406 && e.response.data.error === 'InsufficientCredit') {
                    message = this.$t('campaigns.editCampaign.toasts.campaignScheduleErrorInsufficientCreditMessage').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'UnsubscriptionLinkMissingInPlainContent') {
                    message = this.$t('campaigns.editCampaign.toasts.UnsubscriptionLinkMissingInPlainContent').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'CsaDataMissingInPlainContent') {
                    message = this.$t('campaigns.editCampaign.toasts.CsaDataMissingInPlainContent').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'SenderMustHaveCsaData') {
                    message = this.$t('campaigns.editCampaign.toasts.SenderMustHaveCsaData').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'SenderDkimNotValid') {
                    message = this.$t('campaigns.editCampaign.toasts.SenderDkimNotValid').toString()
                }
            }
            this.$root.$vsToast({
                heading,
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.close()
    }

    public async openModal () {
        this.resetFields()
        this.show = true
    }

    private resetFields () {
        this.campaigns = []
        this.currentCampaign = ''
        this.searchFilter = []
        this.searchFieldsFilter = []
    }

    private close () {
        this.show = false
    }

    private trackMixpanelEvent (eventName: string, campaign: any) {
        if (this.Mixpanel) {
            this.Mixpanel.track(
                eventName,
                {
                    distinct_id: this.user._id,
                    'Plan Type': this.user.configuration.name || '',
                    'List Id': this.listId,
                    'Campaign Id': campaign.id,
                    'Contact Id': this.contactId,
                },
            )
        }
    }

    private async getCampaigns (search: string) {
        this.loading = true
        try {
            const resp = await getCampaigns(this.generateQuery(search))
            this.campaigns = resp.data.data
        } catch (e) {
            console.log(e)
            this.campaigns = []
        }
        this.loading = false
    }

    private generateQuery (search: string) {
        const params: any = {
            orderBy: 'sent_start',
            sortedBy: 'desc',
            page: 1,
            limit: 20,
            include: this.canSend,
            searchJoin: 'and',
            searchFields: '',
            search: '',
        }

        const searchFilter = ['status:Sent']
        const searchFieldsFilter = ['status:=']

        searchFilter.push('name:' + search.trim() || '')
        searchFieldsFilter.push('name:like')

        if (!this.canSendEmail || !this.canSendSms) {
            if (this.canSendEmail) {
                searchFilter.push('email.id:0')
                searchFieldsFilter.push('email.id:!=')
            }
            if (this.canSendSms) {
                searchFilter.push('sms.id:0')
                searchFieldsFilter.push('sms.id:!=')
            }
        }
        params.search = searchFilter.join(';')
        params.searchFields = searchFieldsFilter.join(';')
        return params
    }

    private setCurrentCampaign (campaignId: string) {
        this.currentCampaign = this.campaigns.find((el: any) => el.id === campaignId)
    }
}
