var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{attrs:{"variant":"full","noContentPadding":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('VsFullTopBarLayout',{scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"vs-flex vs-items-center"},[_c('div',{staticClass:"vs-flex-auto"},[_c('VsButton',{attrs:{"aspect":"secondary","size":"large"},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c('span',{staticClass:"vs-ml-4 vs-h600"},[_vm._v(" Invia messaggio WhatsApp ")])],1)])]},proxy:true}])},[_c('VsContainer',{attrs:{"fullHeight":""}},[_c('VsSidebarLayout',{attrs:{"hideSidebar":!_vm.integration || !_vm.integration.live},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('VsWhatsappMobile',{staticClass:"vs-mx-auto",staticStyle:{"height":"750px"},attrs:{"full":"","sender":("" + (_vm.integration.sourceNumber)),"text":_vm.formattedBody}})]},proxy:true}])},[(_vm.integration && _vm.integration.live)?_c('div',{staticClass:"vs-relative"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var invalid = ref.invalid;
return [_c('VsInput',{staticClass:"vs-mb-6",attrs:{"label":"Destinatario","readonly":"","size":"large","value":_vm.contact.mobile}}),_c('VsInputSearch',{staticClass:"vs-mb-6",attrs:{"label":"Seleziona un template","size":"large","formatBadgeLabel":_vm.formatBadgeLabel,"placeholder":"Cerca...","boundary":"viewport","options":_vm.templates.map(function (el) {
                                return {
                                    value: el.id,
                                    label: el.name,
                                }
                            }),"debounce":500},on:{"search":_vm.getWhatsappTemplates},model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}}),(_vm.selectedTemplateObject && _vm.selectedTemplateObject.mapped_fields && _vm.selectedTemplateObject.mapped_fields.length > 0)?_c('div',{staticClass:"vs-mb-6"},[_c('div',{staticClass:"vs-h300 vs-mb-2"},[_vm._v(" Valori da sostituire nel messaggio ")]),_vm._l((_vm.cfValues),function(field,index){return _c('div',{key:field.field + index,staticClass:"vs-flex vs-items-center vs-gap-4 vs-body-large vs-mb-4"},[_c('div',{staticClass:"vs-flex-1"},[_vm._v(" "+_vm._s(field.field)+" "),(field.cf)?_c('div',{staticClass:"vs-body-small"},[_vm._v(" "+_vm._s(field.cf.name)+" ")]):_c('div',{staticClass:"vs-body-small vs-text-warning-900"},[_vm._v(" Non trovato sulla lista ")])]),_c('ValidationProvider',{attrs:{"vid":field.field,"name":'Valore',"rules":"required","immediate":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-1",attrs:{"disabled":"","labelHidden":"","size":"large","error":errors.length > 0,"value":field.value}})]}}],null,true)})],1)})],2):_vm._e(),(_vm.loading)?_c('VsLoader',{attrs:{"overlay":""}}):_vm._e(),(invalid)?_c('VsNotification',{staticClass:"vs-mb-4",attrs:{"heading":"Non puoi inviare un messaggio WhatsApp se manca uno qualsiasi dei valori da sostituire","aspect":"warning"}},[_vm._v(" Verifica che il campi personalizzati siano tutti presenti sulla lista e che i valori siano popolati nella scheda contatto ")]):_vm._e(),(_vm.templateNotSendable)?_c('VsNotification',{staticClass:"vs-mb-4",attrs:{"heading":"Questo template è stato creato da meno di 20 minuti","aspect":"warning"}},[_vm._v(" WhatsApp non permette l'invio di messaggi con template creati da meno di 20 minuti ")]):_vm._e(),_c('VsButton',{attrs:{"disabled":!_vm.selectedTemplate || _vm.loading || invalid || _vm.templateNotSendable,"loading":_vm.loading,"size":"large"},on:{"click":function($event){return passes(_vm.sendWhastappMessage)}}},[_vm._v(" Invia ")])]}}],null,false,682995287)})],1):_c('VsWhatsappIntergation',{attrs:{"integration":_vm.integration},on:{"integration-created":_vm.getIntegration}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }