




































































































































































































































































































































































































































































































































































































































































import { updateListContactById, changeContactStatus, removeTagToContact, recoveryHardBounce } from '@/api/consoleApi/recipients'
import { CustomField, CustomFieldTypeEnum } from '@/utils/customFields'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsFixedBottomActions from '@/components/VsFixedBottomActions/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCustomFieldGenerator from '@/modules/lists/components/VsCustomFieldGenerator/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'
import { get, isEqual, orderBy } from 'lodash'
import { createCustomField } from '@/api/consoleApi/customFields'
import VsCustomFieldsModal from '@/modules/lists/components/VsCustomFieldsModal/Index.vue'
import VsSendSingleCampaignModal from '@/modules/lists/components/VsSendSingleCampaignModal/Index.vue'
import VsSendSmsModal from '@/modules/sms/components/VsSendSmsModal/Index.vue'
import {
    IContact,
    ContactStatusEnum,
    ContactBounceEnum,
} from '@/api/consoleApi/types'
import { createSuppression, deleteSuppression } from '@/api/consoleApi/suppressions'
import { UserModule } from '@/store/modules/user'
import VsWhatsappModal from '@/modules/whatsapp/components/VsWhatsappModal/Index.vue'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'ContactDetails',
    components: {
        VsSidebarLayout,
        MyAccordion,
        VsDropdownButton,
        VsCustomFieldGenerator,
        VsFixedBottomActions,
        VsLoader,
        VsCustomFieldsModal,
        VsSendSmsModal,
        VsConfirm,
        VsSendSingleCampaignModal,
        VsWhatsappModal,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) list!: any
    @Prop({ default: () => [], required: true }) customFields!: CustomField[]
    @Prop({ default: null, required: true }) contact!: IContact
    @Prop({ default: false, required: true }) emailSuppression!: any
    @Prop({ default: false, required: true }) smsSuppression!: any
    @Prop({ default: null, required: true }) customFieldsValues!: {[key: string]: any} | null
    @Prop({ default: null, required: true }) startCustomFieldsValues!: {[key: string]: any} | null
    private loading = false
    private showDetails = false
    private loadingOptIn = false
    private automationBehaviorsTriggers = false
    private sendConfirmationEmailTrigger = false

    $refs!: any

    get listId () {
        return this.$route?.params?.listId
    }

    get hasMock () {
        return AppModule.hasMocks
    }

    get user () {
        return UserModule.user
    }

    get hasTag () {
        return this.user.configuration.rules.tag
    }

    get contactTags () {
        if (!this.contact || !this.contact.tags) return []
        return orderBy(this.contact.tags.data, 'created_at', 'desc')
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get emailCompliance () {
        return this.contact && this.contact.subscription.status === ContactStatusEnum.subscribed && this.contact.bounce === ContactBounceEnum.notBounced && !this.emailSuppression
    }

    get smsCompliance () {
        return this.contact && this.contact.subscription.status === ContactStatusEnum.subscribed && !this.smsSuppression
    }

    get areValuesDifferent () {
        return !isEqual(this.customFieldsValues, this.startCustomFieldsValues)
    }

    get isOptIn () {
        if (!this.contact) return false
        return this.contact.subscription.status === ContactStatusEnum.optIn
    }

    get isSubscribed () {
        if (!this.contact) return false
        return this.contact.subscription.status === ContactStatusEnum.subscribed
    }

    get isUnsubscribed () {
        if (!this.contact) return false
        return this.contact.subscription.status === ContactStatusEnum.unsubscribed
    }

    get isOptOut () {
        if (!this.contact) return false
        return this.contact.subscription.status === ContactStatusEnum.optOut
    }

    get isSuppressed () {
        return !!(this.emailSuppression || this.smsSuppression)
    }

    get getUnsubscriptionMotivation () {
        if (this.contact.subscription.status !== 'Unsubscribed') return
        return this.$t(`lists.contact.unsubscriptionMotivations[${parseInt(this.contact.unsubscription.motivation) - 1}]`)
    }

    private async submitForm () {
        if (this.loading) return
        const isValid = await this.$refs.contactForm.validate()
        if (!isValid) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del contatto, ricontrolla i campi',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        this.loading = true
        try {
            await updateListContactById(this.listId, this.contactId, this.generateSubscribeData())
            this.$root.$vsToast({
                heading: 'Contatto modificato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$emit('form-saved')
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del contatto, ricontrolla i campi',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            this.addServerErrors(e)
        }
        this.loading = false
    }

    private addServerErrors (e: unknown) {
        if (axios.isAxiosError(e)) {
            if (e.response?.status === 400) {
                const message = e.response?.data?.message
                if (message) {
                    const fieldNames = Object.keys(this.customFieldsValues || {})
                    for (const error in message) {
                        if (error.includes('custom_fields.')) {
                            const errArr = error.split('.')
                            this.$refs.contactForm.setErrors({
                                [fieldNames[parseInt(errArr[1])]]: this.getMessageError(message[error][0]),
                            })
                        }
                        if (error.includes('mobile')) {
                            this.$refs.contactForm.setErrors({
                                mobile: this.getMessageError(message[error][0]),
                            })
                        }
                        if (error.includes('email_address')) {
                            this.$refs.contactForm.setErrors({
                                email: this.getMessageError(message[error][0]),
                            })
                        }
                    }
                }
            }
        }
    }

    /* questa parte deve diventare una utils con le stringhe definite in base alla stringa match esatto */
    private getMessageError (messageError: string) {
        if (!messageError) return 'Il campo non ha un formato valido'

        if (messageError.includes('mobile number is not valid')) {
            return this.$t('customValidations.libphoneNumberMobile')
        }
        if (messageError.includes('has already been taken.')) {
            return 'Il valore inserito deve essere univoco'
        }
        if (messageError.includes('field is required.')) {
            return 'Il campo è obbligatorio'
        }
        if (messageError.includes('must be a number.')) {
            return 'Il campo deve essere un numero'
        }
        if (messageError.includes('field must be true or false.')) {
            return 'Il campo deve essere di tipo buleano'
        }
        if (messageError.includes('must be a valid email address.')) {
            return 'Il campo deve essere un indirizzo email valido'
        }
        if (messageError.includes('must be valid date (format YYYY-MM-DD)') || messageError.includes('is not a valid date.')) {
            return 'Il campo deve essere una data valida (formato YYYY-MM-DD)'
        }
        if (messageError.includes('must be valid time string (HH:MM:SS)')) {
            return 'Il campo non ha un formato di tipo temporale valido (formato HH:MM:SS)'
        }
        if (messageError.includes('must be a valid IP address.')) {
            return 'Il campo deve essere un indirizzo IP valido'
        }

        return 'Il campo non ha un formato valido'
    }

    private generateSubscribeData () {
        return {
            mobile: this.customFieldsValues?.mobile,
            email_address: this.customFieldsValues?.email,
            custom_fields: this.generateCustomFieldsFromValues(),
        }
    }

    private generateCustomFieldsFromValues () {
        const customFields = []
        for (const fieldId in this.customFieldsValues) {
            if (['mobile', 'email'].includes(fieldId)) continue
            customFields.push({
                id: parseInt(fieldId),
                value: this.parseEmptyArrayValues(this.customFieldsValues[fieldId]),
            })
        }
        return customFields
    }

    private parseEmptyArrayValues (value: any) {
        if (Array.isArray(value) && value.length === 0) return null
        return value
    }

    private async createCustomField (customField: any) {
        this.$refs.vsCustomFieldsModal.loading = true
        try {
            customField.recipient_id = this.listId
            await createCustomField(customField)
            this.$refs.vsCustomFieldsModal.loading = false
            this.$refs.vsCustomFieldsModal.closeModal()
            this.$emit('customfield-created')
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Campo personalizzato creato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 403) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Hai raggiunto il limite di campi personalizzati creabili per il tuo piano',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$refs.vsCustomFieldsModal.loading = false
                    return
                }

                const placeholderError = get(e, 'response.data.message.placeholder', false)
                if (placeholderError) {
                    message = 'Il placeholder inserito non è valido, prova con un altro valore'
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la creazione del campo personalizzato',
                message,
                aspect: VsToastAspectEnum.alert,
            })
            this.$refs.vsCustomFieldsModal.loading = false
        }
    }

    private openCustomFieldModal (customField?: CustomField) {
        this.$refs.vsCustomFieldsModal.openModal(
            {
                unique: false,
                showMobile: !customField && !this.customFields.find((el: any) => el.type === CustomFieldTypeEnum.mobile),
                showEmail: !customField && !this.customFields.find((el: any) => el.type === CustomFieldTypeEnum.email),
            },
            customField,
        )
    }

    private resetTriggers () {
        this.automationBehaviorsTriggers = false
        this.sendConfirmationEmailTrigger = false
    }

    private async resetContact () {
        try {
            await this.$refs.resetConfirm.openConfirm()
        } catch (e) {
            return
        }

        let message = ''
        let errorTimeout = 3000
        this.loading = true
        try {
            const resp = await recoveryHardBounce(this.listId, this.contactId)
            if (resp.data.error) {
                message = resp.data.message
                errorTimeout = 10000
                throw new Error()
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Indirizzo email ripristinato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('form-saved')
        } catch (e) {
            this.$root.$vsToast({
                timeout: errorTimeout,
                heading: 'Tentativo di ripristino fallito',
                message,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async unsubscribeContact () {
        this.resetTriggers()
        try {
            await this.$refs.unsubscribeContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        const triggers = {
            automation: this.automationBehaviorsTriggers,
            behaviors: this.automationBehaviorsTriggers,
            send_confirmation_email: this.sendConfirmationEmailTrigger,
        }
        try {
            await changeContactStatus(this.listId, this.contactId, {
                status: ContactStatusEnum.unsubscribed,
                triggers,
                ip: '0.0.0.0',
            })
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Contatto disiscritto con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('form-saved')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la disiscrizione del contatto',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async suppress (suppressionType: 'email_address' | 'mobile', suppressionData: string) {
        try {
            if (suppressionType === 'email_address') {
                await this.$refs.addEmailSuppressionConfirm.openConfirm()
            } else {
                await this.$refs.addSmsSuppressionConfirm.openConfirm()
            }
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await createSuppression({ [suppressionType]: suppressionData })
            this.$emit('form-saved')
            const heading = suppressionType === 'email_address' ? 'Indirizzo email aggiunto con successo alla lista di soppressione' : 'Telefono cellulare aggiunto con successo alla lista di soppressione'
            this.$root.$vsToast({
                heading,
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            console.log(e)
            const heading = suppressionType === 'email_address' ? 'Errore durante l\'inserimento dell\'indirizzo email alla lista di soppressione' : 'Errore durante l\'inserimento del telefono cellulare alla lista di soppressione'
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    if (e.response?.data?.message?.mobile && e.response.data.message.mobile[0].includes('already suppressed')) {
                        message = this.$t('lists.suppression.addSuppressionModal.errorMobile').toString()
                    }
                    if (e.response?.data?.message?.email_address && e.response.data.message.email_address[0].includes('already suppressed')) {
                        message = this.$t('lists.suppression.addSuppressionModal.errorEmail').toString()
                    }
                }
            }
            this.$root.$vsToast({
                heading,
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }

    private async deleteSuppression (id: string, suppressionType: 'email_address' | 'mobile') {
        try {
            if (suppressionType === 'email_address') {
                await this.$refs.deleteEmailSuppressionConfirm.openConfirm()
            } else {
                await this.$refs.deleteSmsSuppressionConfirm.openConfirm()
            }
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteSuppression(id)
            this.$emit('form-saved')
            this.$root.$vsToast({
                heading: this.$t('lists.suppression.list.deleteSuccess'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: this.$t('lists.suppression.list.deleteError'),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async subscribeContact () {
        this.resetTriggers()
        try {
            await this.$refs.subscribeContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        const triggers = {
            automation: this.automationBehaviorsTriggers,
            behaviors: this.automationBehaviorsTriggers,
            send_confirmation_email: this.sendConfirmationEmailTrigger,
        }
        try {
            await changeContactStatus(this.listId, this.contactId, {
                status: ContactStatusEnum.subscribed,
                triggers,
                ip: '0.0.0.0',
            })
            this.$emit('form-saved')
            this.$root.$vsToast({
                heading: 'Contatto iscritto con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l\'iscrizione del contatto',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async optInContact () {
        this.resetTriggers()
        try {
            await this.$refs.optInContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        const triggers = {
            automation: this.automationBehaviorsTriggers,
            behaviors: this.automationBehaviorsTriggers,
            send_confirmation_email: this.sendConfirmationEmailTrigger,
        }
        try {
            await changeContactStatus(this.listId, this.contactId, {
                status: ContactStatusEnum.optIn,
                triggers,
                ip: '0.0.0.0',
            })
            this.$emit('form-saved')
            this.$root.$vsToast({
                heading: 'Status del contatto modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante il cambio di status',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async sendOptin () {
        this.$emit('send-confirmation-email')
    }

    private otherTags () {
        this.$router.push({ name: 'contactTags' })
    }

    private async removeAssign (tagId: any) {
        try {
            await this.$refs.removeConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await removeTagToContact(this.listId, tagId, this.contactId)

            this.$root.$vsToast({
                heading: 'Etichetta rimossa con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$emit('form-saved')
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private sendCampaign () {
        this.$refs.sendSingleCampaignModal.openModal()
    }

    openWhatsAppModal () {
        this.$refs.vsWhatsappModal.openModal()
    }

    openSmsModal () {
        this.$refs.vsSendSmsModal.openModal(this.contact.mobile)
    }
}
